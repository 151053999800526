import React, { Suspense, useEffect } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import './scss/style.scss'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsAuth, successAuth } from './store/authSlice'
import Loader from './components/Loader'
import useApi from './hooks/useApi'
import AuthService from './services/authService'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const ForgotPassword = React.lazy(() => import('./views/pages/forgotPassword/ForgotPassword'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
  const auth = useSelector(selectIsAuth)
  const { request, data } = useApi(AuthService.adminAuthorize, true)
  const dispatch = useDispatch()

  const validateAdmin = async (jwt) => {
    request()
  }

  useEffect(() => {
    const jwt = localStorage.getItem('jwt')
    if (jwt) {
      validateAdmin(jwt)
    } else {
      dispatch(
        successAuth({
          isLoading: false,
        }),
      )
    }
  }, [])

  useEffect(() => {
    if (data) {
      dispatch(
        successAuth({
          jwt: localStorage.getItem('jwt'),
          isLoading: false,
        }),
      )
    }
  }, [data])

  return (
    <>
      {auth.isLoading && <Loader className={'vh100'} />}
      {!auth.isLoading && (
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route
              path="/login"
              name="Login Page"
              element={auth.isAuth ? <Navigate to={'/'} /> : <Login />}
            />
            {/* /forgot-password */}
            <Route
              path="/forgot-password"
              name="Forgot password Page"
              element={auth.isAuth ? <Navigate to={'/'} /> : <ForgotPassword />}
            />
            <Route path="/404" name="Page 404" element={<Page404 />} />
            <Route path="/500" name="Page 500" element={<Page500 />} />
            <Route
              path="*"
              name="Home"
              element={auth.isAuth ? <DefaultLayout /> : <Navigate to={'/login'} />}
            />
          </Routes>
        </Suspense>
      )}
    </>
  )
}

export default App
