import axios from 'axios'

const apiClient = axios.create({
  // Later read this URL from an environment variable
  // baseURL: 'http://18.158.73.74:3000/api/v1',
  baseURL: 'https://apk.anudar.app/api/v1',
})

export const headers = (arg) => {
  return {
    Authorization: 'Bearer ' + localStorage.getItem('jwt'),
    ...(arg && { ...arg }),
  }
}

export const post = async (arg) => {
  try {
    const {
      data: { data },
    } = await apiClient({
      method: 'Post',
      ...(arg && { ...arg }),
    })

    return data
  } catch (e) {
    console.log(e)
    throw new Error(e?.response?.data?.message || 'Something Went Wrong!')
  }
}

export const get = async (arg) => {
  try {
    const {
      data: { data },
    } = await apiClient({
      method: 'Get',
      ...(arg && { ...arg }),
    })

    return data
  } catch (e) {
    console.log(e)
    throw new Error(e?.response?.data?.message || 'Something Went Wrong!')
  }
}

export const patch = async (arg) => {
  try {
    const {
      data: { data },
    } = await apiClient({
      method: 'Patch',
      ...(arg && { ...arg }),
    })
    return data
  } catch (e) {
    throw new Error(e?.response?.data?.message || 'Something Went Wrong!')
  }
}

export const del = async (arg) => {
  try {
    const {
      data: { data },
    } = await apiClient({
      method: 'Delete',
      ...(arg && { ...arg }),
    })
    return data
  } catch (e) {
    throw new Error(e?.response?.data?.message || 'Something Went Wrong!')
  }
}
